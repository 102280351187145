<template>
  <bem>
    <PartLayout>
      <div v-bem-block:PageHome>
        <PartUnderHeader
          v-if="isProductPublished"
          :underHeaderMenu="underHeaderMenu"
          variant="dark"
        />
        <div v-bem-elem:contentAndSpacersContainer>
          <div v-bem-elem:topSpacer />
          <div v-bem-elem:content>
            <div v-bem-elem:logoContainer>
              <LogoSingleColor />
            </div>
            <div v-bem-elem:beforeBannerTitleSpacer />
            <div v-bem-elem:bannerTitle v-html="bannerTitle" />
            <div v-bem-elem:beforeBannerTextSpacer />
            <div v-bem-elem:bannerText v-html="bannerText" />
          </div>
          <div v-bem-elem:bottomSpacer />
        </div>
        <div v-bem-elem:footerContainer>
          <div v-bem-elem:footer>
            <div v-bem-elem:footerContent v-html="footerContent" />
          </div>
        </div>
      </div>
    </PartLayout>
  </bem>
</template>

<script>
import mdToHtml from '@inyur/markdown-to-html';
import {
  PartUnderHeader,
} from '@inyur/vue-part-header';
import { boolean } from 'boolean';
import PartLayout from '@/src/layout';
import { IS_PRODUCT_PUBLISHED } from '@/env.mjs';
import { getLinkToJoinSite, getLinkToStartOfBookingFlow, getLinkToWikiSite } from '@/links';

export default {
  name: 'PageHome',
  components: {
    PartLayout,
    PartUnderHeader,
    LogoSingleColor: require('./assets/logo-single-color.svg?inline'),
  },
  async asyncData(context) {
    const locale = context.i18n.locale;

    const {
      title: bannerPublishedTitle,
      html: bannerPublishedText
    } = await mdToHtml(import(`../../content/banner-content.${locale}.md`), {
      extended: true,
      splitTitle: true
    });
    const {
      title: bannerNotPublishedTitle,
      html: bannerNotPublishedText
    } = await mdToHtml(import(`../../content/banner-content-not-published.${locale}.md`), {
      extended: true,
      splitTitle: true
    });
    const footerContent = await mdToHtml(import(`../../content/footer-content.${locale}.md`), {
      links: { 'mailto:office@inyur.com': 'mailto:office@inyur.com' }
    });
    return {
      bannerPublishedTitle,
      bannerPublishedText,
      bannerNotPublishedTitle,
      bannerNotPublishedText,
      footerContent,
    };
  },
  computed: {
    underHeaderMenu() {
      return [
        {
          title: this.$i18n.t('Book a workplace'),
          href: this.mainSiteHomePageUrl,
          target: '_blank',
        },
        {
          title: this.$i18n.t('Register coworking'),
          href: this.joinHomePageUrl,
          target: '_blank',
        },
        {
          title: this.$i18n.t('Answers to your questions'),
          href: this.wikiHomePageUrl,
          target: '_blank',
        }
      ];
    },
    isProductPublished() {
      return boolean(this.$route.query?.['ci.product-published']) || boolean(IS_PRODUCT_PUBLISHED);
    },
    bannerTitle() {
      return this.isProductPublished ? this.bannerPublishedTitle : this.bannerNotPublishedTitle;
    },
    bannerText() {
      return this.isProductPublished ? this.bannerPublishedText : this.bannerNotPublishedText;
    },
    mainSiteHomePageUrl() {
      return getLinkToStartOfBookingFlow(this.$i18n.locale);
    },
    wikiHomePageUrl() {
      return getLinkToWikiSite(this.$i18n.locale);
    },
    joinHomePageUrl() {
      return getLinkToJoinSite(this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss">
@import '@/src/_include.scss';

.PageHome {
  display: flex;
  flex-direction: column;
  flex-grow: 1;


  &__contentAndSpacersContainer {
    $header-min-height: 48px;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media #{$phone} {
      min-height: calc(100vh - #{$header-min-height});
    }
  }

  &__topSpacer, &__bottomSpacer {
    min-height: 62px;
    flex-grow: 1;
  }

  &__content {
    @extend %container;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__logoContainer {
    display: flex;
    justify-content: center;

    & svg {
      @media #{$tablet-and-desktop} {
        width: 219px;
        height: 91px;
      }

      @media #{$phone} {
        width: 158px;
        height: 66px;
      }
    }
  }

  &__beforeBannerTitleSpacer, &__beforeBannerTextSpacer {
    max-height: 62px;
    min-height: 24px;
    flex-grow: 1;

    @media #{$phone} {
      max-height: 80px;
    }
  }

  &__bannerTitle {
    h1 {
      color: #FFF;
      text-shadow: 1px 0 6px rgba(0, 0, 0, 0.25), 0 1px 2px #4E5972;
      font-size: 30px;
      font-weight: 700;
      line-height: 132.682%;
      text-align: center;

      @media #{$phone} {
        font-size: 20px;
      }
    }
  }

  &__bannerText {
    p {
      color: #FFF;
      font-size: 20px;
      line-height: 150.182%;
      text-align: center;
      text-shadow: 1px 0 6px rgba(0, 0, 0, 0.25), 0 1px 2px #4E5972;

      strong {
        font-weight: 700;
      }
    }
  }

  &__footerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 75px;
    background: #003490;
    flex-shrink: 0;
    padding: 8px 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$phone} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__footerContent {
    color: #FFF;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 300;

    a {
      text-decoration: underline;
      color: #FFF;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "Book a workplace": "Book a workplace",
    "Register coworking": "Register coworking",
    "Answers to your questions": "Answers to your questions"
  },
  "ru": {
    "Book a workplace": "Забронировать рабочее место",
    "Register coworking": "Зарегистрировать коворкинг",
    "Answers to your questions": "Ответы на ваши вопросы"
  },
  "uk": {
    "Book a workplace": "Забронювати робоче місце",
    "Register coworking": "Зареєструвати коворкінг",
    "Answers to your questions": "Відповіді на ваші запитання"
  },
  "he": {
    "Book a workplace": "הזמינו מקום עבודה",
    "Register coworking": "לרשום עבודה משותפים",
    "Answers to your questions": "תשובות לשאלותיך"
  }
}
</i18n>
