import assert from 'assert';
import {
  getLinkToStartOfBookingFlow as _getLinkToStartOfBookingFlow,
  getLinkToJoinSite as _getLinkToJoinSite,
  getLinkToWikiSite as _getLinkToWikiSite
} from '@inyur/links';

import { MAIN_DOMAIN } from './env.mjs';
assert.ok(MAIN_DOMAIN, 'MAIN_DOMAIN required');

export const getLinkToStartOfBookingFlow = lang => _getLinkToStartOfBookingFlow({ MAIN_DOMAIN, lang });
export const getLinkToWikiSite = lang => _getLinkToWikiSite({ MAIN_DOMAIN, lang });
export const getLinkToJoinSite = lang => _getLinkToJoinSite({ MAIN_DOMAIN, lang });
