<template>
  <bem>
    <div v-bem-block:Layout>
      <PartHeaderBasic mobileMenuScreenName="main">
        <PartLanguageSwitcher openerButtonVariant="bold-dark" />
      </PartHeaderBasic>
      <MobileMenu screenName="main" no-under-footer>
        <PartMobileMenuContainerGray>
          <PartMobileButtonLanguage />
        </PartMobileMenuContainerGray>
        <!-- // TODO when in prod - add links to other sites -->
        <!-- <PartFooter /> -->
      </MobileMenu>
      <div v-bem-elem:content>
        <div v-bem-elem:bgImageContainer>
          <BgRandomImage api-image-url="/v1/random-image/page-login-background" />
          <div v-bem-elem:bgImageGradient />
        </div>
        <slot />
      </div>
    </div>
  </bem>
</template>

<script>
import { BgRandomImage } from '@inyur/nuxt-bg-random-image';
import {
  PartHeaderBasic,
  PartLanguageSwitcher,
  MobileMenu,
  PartMobileMenuContainerGray,
  PartMobileButtonLanguage
} from '@inyur/vue-part-header';

export default {
  components: {
    BgRandomImage,
    PartHeaderBasic,
    PartLanguageSwitcher,
    MobileMenu,
    PartMobileMenuContainerGray,
    PartMobileButtonLanguage
  },
};
</script>

<style lang="scss">
@import '@/src/_include.scss';
@import '@inyur/nuxt-bg-random-image';
@import '@inyur/vue-part-header';

.Layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    z-index: 0; // Due to __bgImageContainer: z-index: -1;
  }

  &__bgImageContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &__bgImageGradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        58.41deg,
        rgba(57, 164, 243, 0.245) -8.86%,
        rgba(15, 64, 175, 0.7) 47.6%
    );
  }
}
</style>

